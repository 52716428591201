import React, {Component} from "react";
import DashboardUserTable from "./DashboardUserTable";

class DashboardContent extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            usersCount: 0
        });
    }

    handleUsersCount = (val) => {
        this.setState({usersCount: val})
    }

    render() {
        return (
           <React.Fragment>

               <div className="row row-cards">
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-green">
                                   6%
                                   <i className="fe fe-chevron-up"></i>
                               </div>
                               <div className="h1 m-0">{this.state.usersCount}</div>
                               <div className="text-muted mb-4">Companies</div>
                           </div>
                       </div>
                   </div>
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-red">
                                   -3%
                                   <i className="fe fe-chevron-down"></i>
                               </div>
                               <div className="h1 m-0">17</div>
                               <div className="text-muted mb-4">Registered this Week</div>
                           </div>
                       </div>
                   </div>
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-green">
                                   9%
                                   <i className="fe fe-chevron-up"></i>
                               </div>
                               <div className="h1 m-0">7</div>
                               <div className="text-muted mb-4">New Replies</div>
                           </div>
                       </div>
                   </div>
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-green">
                                   3%
                                   <i className="fe fe-chevron-up"></i>
                               </div>
                               <div className="h1 m-0">27.3K</div>
                               <div className="text-muted mb-4">Followers</div>
                           </div>
                       </div>
                   </div>
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-red">
                                   -2%
                                   <i className="fe fe-chevron-down"></i>
                               </div>
                               <div className="h1 m-0">$95</div>
                               <div className="text-muted mb-4">Daily Earnings</div>
                           </div>
                       </div>
                   </div>
                   <div className="col-6 col-sm-4 col-lg-2">
                       <div className="card">
                           <div className="card-body p-3 text-center">
                               <div className="text-right text-red">
                                   -1%
                                   <i className="fe fe-chevron-down"></i>
                               </div>
                               <div className="h1 m-0">621</div>
                               <div className="text-muted mb-4">Products</div>
                           </div>
                       </div>
                   </div>
               </div>

               <div className="row row-cards row-deck">
                   <DashboardUserTable
                    usersCount={this.handleUsersCount}
                   />

               </div>
           </React.Fragment>
        )
    }
}

export default DashboardContent;
