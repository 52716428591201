import React, {Component} from "react";
import DashboardUserTable from "../Dashboard/DashboardUserTable";
import PageHeader from "../Layout/PageHeader";

class Users extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        title={"Users"}
                    />

                    <div className="row">
                        <DashboardUserTable/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users;