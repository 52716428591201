import React, {Component} from "react";
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";

export default class InvoicesByCompany extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            invoices: [],
            error: null
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.invoices.length !== prevProps.invoices.length) {
            this.setState({invoices: this.props.invoices});
        }

    }

    componentDidMount() {
    }

    retrieveInvoiceUrl = (invoiceID) => {
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/invoices/invoice/' + invoiceID)
            .then(res => {
                if (res.code === 800) {
                    window.open(res.data.invoice_url, "_blank");
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            })
    }

    render() {
        return (

            <div className="row row-cards row-deck">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Invoice for </h3>
                    </div>
                    <div className="table-responsive">
                        <table
                            className="table table-hover table-outline table-vcenter text-nowrap card-table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Invoice#</th>
                                <th>Customer Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Due Date</th>
                                <th>Amount</th>
                                <th>Balance Due</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.invoices.map((invoice, index) => <React.Fragment key={index}>
                                    <tr>
                                        <td>{invoice.invoice_date}</td>
                                        <td><strong>{invoice.number}</strong></td>
                                        <td>{invoice.customer_name}</td>
                                        <td>{invoice.email}</td>
                                        <td>
                                            <span className="text-green">{invoice.status}</span>
                                        </td>
                                        <td>{invoice.due_date}</td>
                                        <td className="text-right">{invoice.currency_symbol}{invoice.total}</td>
                                        <td className="text-right">{invoice.currency_symbol}{invoice.balance}</td>
                                        <td>
                                            <button type="button" className="btn btn-secondary btn-sm" onClick={(e) => {
                                                this.retrieveInvoiceUrl(invoice.invoice_id)
                                            }}><i className="fe fe-search"/></button>
                                        </td>
                                    </tr>

                                </React.Fragment>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        )
    }
}