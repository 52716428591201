import React, {Component} from "react";
import PageHeader from "../Layout/PageHeader";
import helpers from '../constants';
import {API_Call} from "../util";
import {NotificationManager} from "react-notifications";

export default class EnquiryList extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            enquiries: [],
            error: null
        })
    }

    componentDidMount() {
        API_Call(helpers.authServiceUrl + '/api/saas/enquiries')
            .then(res => {
                if (res.code === 800) {
                    this.setState({enquiries: res.data.data});
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            });
    }

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        title={"Enquiry List"}
                    />

                    <div className="row row-cards row-deck">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Enquiries</h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter text-nowrap">
                                        <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>No. of Employees</th>
                                            <th>Industry</th>
                                            <th>Licenses Requested</th>
                                            <th>No. of Technicians</th>
                                            <th>Notes</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.enquiries.map((enquiry, index) => <React.Fragment key={index}>
                                                <tr>
                                                    <td><span className="text-muted">{index + 1}</span></td>
                                                    <td>{enquiry.created_at}</td>
                                                    <td>{enquiry.name}</td>
                                                    <td>{enquiry.email}</td>
                                                    <td>{enquiry.employees_no}</td>
                                                    <td>{enquiry.industry.name}</td>
                                                    <td>{enquiry.licenses_required}</td>
                                                    <td>{enquiry.technicians_no}</td>
                                                    <td>{enquiry.notes}</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}