import React, {Component} from 'react';
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from 'react-notifications';

class SubscriptionDetail extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            cardDetail: {},
            customerDetail: {},
            subscriptionDetail: {},
            error: null
        });
    }

    componentDidMount() {
        var id = this.props.companyID;
        API_Call(helpers.subscriptionServiceUrl + "/api/saas/subscription/" + id)
            .then(res => {
                if (res.code === 800) {
                    this.setState({
                        cardDetail: res.data.card,
                        customerDetail: res.data.customer,
                        subscriptionDetail: res.data
                    });
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');
            });
    }

    render() {
        return (
            <React.Fragment>

                <div className="card-body p-t-0 p-b-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-status bg-green"></div>
                                <div className="card-header">
                                    <h3 className="card-title">Card</h3>
                                    <div className="card-options">
                                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i
                                            className="fe fe-chevron-up"></i></a>
                                    </div>
                                </div>
                                <div className="card-body p-t-0 p-b-0 p-l-12 p-r-12">
                                    <table className="table table-borderless">
                                        <tr>
                                            <td>Expiry Date</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{ this.state.cardDetail && this.state.cardDetail.expiry_month}/{this.state.cardDetail && this.state.cardDetail.expiry_year}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Last Four Digits</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-danger">{this.state.cardDetail && this.state.cardDetail.last_four_digits}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Gateway</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{this.state.cardDetail && this.state.cardDetail.payment_gateway}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-status bg-green"></div>
                                <div className="card-header">
                                    <h3 className="card-title">Customer</h3>
                                    <div className="card-options">
                                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i
                                            className="fe fe-chevron-up"></i></a>
                                    </div>
                                </div>
                                <div className="card-body p-t-0 p-b-0 p-l-12 p-r-12">
                                    <table className="table table-borderless">
                                        <tr>
                                            <td>Customer Name</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{this.state.customerDetail.display_name}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-success">{this.state.customerDetail.email}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>IP Address</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-danger">{this.state.customerDetail.ip_address}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Gateway</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{this.state.customerDetail.website}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-status bg-green"></div>
                                <div className="card-header">
                                    <h3 className="card-title">Subscription</h3>
                                    <div className="card-options">
                                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i
                                            className="fe fe-chevron-up"></i></a>
                                    </div>
                                </div>
                                <div className="card-body p-t-0 p-b-0 p-l-12 p-r-12">
                                    <table className="table table-borderless">
                                        <tr>
                                            <td>Subscription Status</td>
                                            <td className="text-right">
                                       <span className="badge badge-success">{this.state.subscriptionDetail.status}
                                       </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subscription Amount</td>
                                            <td className="text-right">
                                       <span
                                           className="badge badge-danger">{this.state.subscriptionDetail.currency_symbol} {this.state.subscriptionDetail.amount} {this.state.subscriptionDetail.currency_code}
                                       </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Activation Date</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{this.state.subscriptionDetail.activated_at}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Last Billing Date</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-success">{this.state.subscriptionDetail.last_billing_at}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Next Billing Date</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-danger">{this.state.subscriptionDetail.next_billing_at}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Expires At</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-danger">{this.state.subscriptionDetail.current_term_ends_at}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Repeat Every</td>
                                            <td className="text-right">
                                            <span
                                                className="badge badge-default">{this.state.subscriptionDetail.interval} {this.state.subscriptionDetail.interval_unit}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SubscriptionDetail;
