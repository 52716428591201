import React, {Component} from 'react';
import {API_Call, nullChecker} from "../util";
import helpers from "../constants";
import {NotificationManager} from 'react-notifications';

class Addons extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            addons: [],
            error: null
        });
    }

    componentDidMount() {
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/addons')
            .then(res => {
                if (res.code === 800) {
                    this.setState({addons: res.data});
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            });
    }

    render() {
        return (
            <div className="row row-cards row-deck">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Addons ( {this.state.addons.length} )</h3>
                        </div>
                        <div className="table-responsive">
                            <table
                                className="table table-hover table-outline table-vcenter  card-table">
                                <thead>
                                <tr>
                                    <th>Sn</th>
                                    <th>Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.addons.map((addon, index) => <React.Fragment key={index}>
                                            <tr>
                                                <td><span className="text-muted">{index + 1}</span></td>
                                                <td>
                                                    {addon.name}
                                                    <div className="small "> {nullChecker(addon.description)} </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Addons;