import helpers from "../constants";
import decode from "jwt-decode";

export default class Service {
    constructor() {
        this.authServiceUrl = helpers.authServiceUrl;
        this.xeroServiceUrl = helpers.xeroServiceUrl;
        this.dbServiceUrl = helpers.dbServiceUrl;
        this.subscriptionServiceUrl = helpers.subscriptionServiceUrl;
    }

    loggedIn() {
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);

            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken);
    }

    setUserToken(userToken) {
        // Saves user token to localStorage
        localStorage.setItem('user_token', userToken);
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token');
    }

    getUserToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('user_token');
    }

    getLoggedInUserDetails() {
        if(this.getUserToken()) {
            var decoded = decode(this.getUserToken());
            return decoded.user;
        }
        return false;
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
        localStorage.removeItem('user_token');
    }

    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
            headers['Company-User-Access'] = this.getUserToken();
        }

        return fetch(url, {
            headers,
            ...options
            })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

}
