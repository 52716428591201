import React, {Component} from "react";
import UserTable from "./Contents/UserTable";
import DatabaseDetail from "./Contents/DatabaseDetail";
import {API_Call} from "../../util";
import helpers from "../../constants";
import UserSubscription from "./Contents/Subscription";
import ZohoEventsByCompany from "../../Subscription/ZohoEventsByCompany";
import {NotificationManager} from "react-notifications";

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            users: [],
            error: false,
            databaseDetail: [],
            currentTab: props.currentTab,
            subscriptionCompanyPlan: {},
            subscriptionMetricPlans: [],
            companyUsersUrl: helpers.authServiceUrl + "/api/saas/user/" + this.props.id
        });
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.currentTab !== this.props.currentTab) {
            this.setState({
                currentTab: this.props.currentTab
            });
        }
    }

    componentDidMount() {
        // this.API_getUsers();

        API_Call(`${helpers.dbServiceUrl}/api/saas/database/` + this.props.id, 'GET')
            .then(res => {
                if (res.code === 800) {
                    this.setState({
                        databaseDetail: res.data
                    });
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');

                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error fetching database details');
            });

        API_Call(`${helpers.subscriptionServiceUrl}/api/saas/plan-details/` + this.props.id, 'GET')
            .then(res => {
                if (res.code === 800) {
                    this.setState({
                        subscriptionCompanyPlan: res.data.companyPlan,
                        subscriptionMetricPlans: res.data.metricPlan,
                    });
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error fetching plan details');
            });
    }

    render() {
        return (
            <React.Fragment>
                {(() => {
                    switch (this.state.currentTab) {
                        case "database":
                            return <DatabaseDetail
                                databaseDetail={this.state.databaseDetail}
                            />;

                        case "subscription":
                            return <UserSubscription
                                id={this.props.id}
                                subscriptionCompanyPlan={this.state.subscriptionCompanyPlan}
                                subscriptionMetricPlans={this.state.subscriptionMetricPlans}
                            />;

                        case "zoho-notifications":
                            return <ZohoEventsByCompany
                                companyPlanId={this.state.subscriptionCompanyPlan.id}
                            />;

                        default:
                            return <UserTable
                                id={this.props.id}
                            />;
                    }
                })()}
            </React.Fragment>
        )

    }
}

export default Content;
