import React, {Component} from "react";
import helpers from '../constants';
import {API_Call} from '../util';
import {Link, NavLink} from "react-router-dom";
import TotalCard from '../Layout/TotalCard';
import {NotificationManager} from "react-notifications";
import NotificationPopup from "../Notification/NotificationPopup";

class SubscribedUsers extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            users: [],
            usersCount: 0,
            error: null
        });
    }

    componentDidMount() {
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/plans/' + this.props.match.params.planCode)
            .then(res => {
                if (res.code === 800) {
                    this.setState({users: res.data, usersCount: res.data.length});
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            })
    }

    render() {
        let usersDetail = {
            delta: '0',
            label: 'Users',
            totalCount: this.state.usersCount,
        };
        return (
            <div className="my-3 my-md-5">
                <div className="container">


                    <div className="page-header">
                        <h1 className="page-title">
                            {this.props.match.params.planName}
                        </h1>
                    </div>

                    <div className="row row-cards row-deck">

                        <TotalCard details={usersDetail}/>

                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Current Users in Plan</h3>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        className="table table-hover table-outline table-vcenter text-nowrap card-table">
                                        <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Company Code</th>
                                            <th>Email</th>
                                            <th>Expiry</th>
                                            <th>Status</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.users.map((user, index) => <React.Fragment key={index}>
                                                    <tr>
                                                        <td><span className="text-muted">{index + 1}</span></td>
                                                        <td><NavLink to={"/company-details/" + user.company_code}
                                                                     className="dropdown-item"> {user.company_code}  </NavLink>
                                                        </td>
                                                        <td> {user.email} </td>
                                                        <td> {user.expires_at} </td>
                                                        <td> {user.subscription_status} </td>
                                                        <td className="">
                                                            <NotificationPopup
                                                                companyID={user.company_code}
                                                            />
                                                            <button type="button"
                                                                    className="btn btn-secondary btn-sm"
                                                                    data-toggle="collapse"
                                                                    data-target={`#collapseUser_${user.id}`}>
                                                                <i className="fe fe-list"/>
                                                                AddOns
                                                            </button>
                                                            <Link to={`/events/company/${user.id}`}
                                                                  className="btn btn-secondary btn-sm">
                                                                <i className="fe fe-calendar"></i>Events</Link>
                                                            <Link to={`/invoices/company/${user.id}`}
                                                                  className="btn btn-secondary btn-sm">
                                                                <i className="fe fe-file"></i> Invoices </Link>
                                                            <div className="dropdown">
                                                                <button type="button"
                                                                        className="btn btn-secondary btn-sm dropdown-toggle"
                                                                        data-toggle="dropdown">
                                                                    <i className="fe fe-settings"/>
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" href="#">link</a>
                                                                    <a className="dropdown-item" href="#">Dropdownlink</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="collapse tableCollapseStyle"
                                                        id={`collapseUser_${user.id}`}>
                                                        <td colSpan={6}>
                                                            <div className="">
                                                                {user.metric_p_lans.map((addon, ind) => <div key={ind}>
                                                                        {addon.name}
                                                                        {addon.pivot.values}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SubscribedUsers;