import React, {Component} from "react";
import AuthService from "../Services/AuthService";
import {NotificationManager} from 'react-notifications';

class Login extends Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.Auth = new AuthService();

        this.state = ({
            'error': false
        })
    }

    handleLoginSubmit = (e) => {
        e.preventDefault();

        this.Auth.login(this.state.email, this.state.password)
            .then(res => {
                if (res.code === 800) {
                    window.location.replace("/dashboard");
                } else
                    this.setState({error: res.message});
                NotificationManager.error(res.message, 'Error');

            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            });
    };

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    };

    componentWillMount() {
        if (this.Auth.loggedIn()) {
            window.location.replace("/dashboard");
        }
    }

    showErrorMessage = (message) => {
        return (
            <div id="notify-user-div" className="card-alert alert alert-danger mb-0">
                {message}
            </div>
        )
    };

    render() {
        return (
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="text-center mb-6">
                                <img src={require('../../Assets/saas_sked.png')} className="h-6" alt=""/>
                            </div>

                            <form
                                className="card"
                                onSubmit={this.handleLoginSubmit}
                                method="post">

                                <div className="card-body p-6">
                                    <div className="card-title">Login to your account</div>
                                    {
                                        this.state.error ?
                                            this.showErrorMessage(this.state.error)
                                            :
                                            ''
                                    }
                                    <div className="form-group">
                                        <label className="form-label">Email address</label>
                                        <input onChange={this.handleChange}
                                               type="email"
                                               name="email"
                                               className="form-control"
                                               id="exampleInputEmail1"
                                               aria-describedby="emailHelp"
                                               placeholder="Enter email"/>
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">
                                            Password
                                            <a href="./forgot-password.html" className="float-right small">I forgot
                                                password</a>
                                        </label>
                                        <input onChange={this.handleChange}
                                               type="password"
                                               name="password"
                                               className="form-control"
                                               id="exampleInputPassword1"
                                               placeholder="Password"/>
                                    </div>

                                    <div className="form-group">
                                        <label className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"/>
                                            <span className="custom-control-label">Remember me</span>
                                        </label>
                                    </div>
                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                                    </div>
                                </div>
                            </form>
                            {/*<div className="text-center text-muted">*/}
                            {/*Don't have account yet? <a href="./register.html">Sign up</a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
