import React, {Component} from "react";

import PageHeader from "../Layout/PageHeader";
import DashboardContent from "./DashboardContent";
import AuthService from "../Services/AuthService";
import {Redirect} from "react-router-dom";

class Dashboard extends Component {
    constructor(props) {
        super(props);
    }

    isLoggedIn() {
        let Auth = new AuthService();
        if(!Auth.loggedIn()) {
            //alert('haha');
            return false;
        }
        return true;
    }
    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        title={"Dashboard"}
                    />
                    {
                        !this.isLoggedIn() ?
                            (
                                <Redirect to={"/login"}/>
                            ) : (
                                <DashboardContent />
                            )
                    }


                </div>
            </div>
        )
    }
}

export default Dashboard;
