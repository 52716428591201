import React, {Component} from "react";
import {prettify} from "../../../util";
import SubscriptionDetail from "../../../Subscription/SubscriptionDetail";

class UserSubscription extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
    }

    normalizeOutput = (key, value) => {
        switch (key) {
            case 'code':
            case 'expires_at':
            case 'company_code':
            case 'plan_code':
                return <span className="text-capitalize">{value}</span>;
            case 'subscription_status':
                return value ?
                    (<span className="tag tag-success text-capitalize">{"active"}</span>)
                    :
                    (<span className="tag tag-danger text-capitalize">{"inactive"}</span>);
            case 'pricing_scheme':
                var parsedValue = JSON.parse(value);
                return (
                    <table className="table table-bordered">
                        {
                            Object.keys(parsedValue).map((value, index) =>
                                Object.keys(parsedValue[value]).map((v, i) =>
                                    <tr key={i}>
                                        <td className="">{v}</td>
                                        <td className="">{parsedValue[value][v]}</td>
                                    </tr>
                                )
                            )
                        }
                    </table>
                );

            case 'pivot':
                return (
                    <div className="tag tag-green">
                        {value}
                    </div>
                );

            default:
                return value;
        }
    };

    render() {
        return (
            <React.Fragment>
                <SubscriptionDetail
                    companyID={this.props.id}
                />

                <div className="card-body p-t-0 border-0">

                    <div className="card">
                        <div className="card-status bg-purple"></div>
                        <div className="card-header">
                            <h4 className="card-title">Plan</h4>
                            <div className="card-options">
                                <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i
                                    className="fe fe-chevron-up"></i></a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {
                                    Object.keys(this.props.subscriptionCompanyPlan).map((data, index) =>
                                        <div className="col-sm-4 m-b-8" key={index}>
                                            <div className="">{prettify(data)}</div>
                                            <strong>{this.normalizeOutput(data, this.props.subscriptionCompanyPlan[data])}</strong>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>

                    {
                        this.props.subscriptionMetricPlans.map((data, index) =>

                            <div className="card">
                                <div className="card-status bg-cyan"></div>
                                <div className="card-header">
                                    <h4 className="card-title">{data.name}</h4>
                                    {/*<div className="card-options">*/}
                                        {/*<a href="#" id={index} className="card-options-collapse"*/}
                                           {/*data-toggle={`card-collapse-${index}`}><i*/}
                                            {/*className="fe fe-chevron-up"></i></a>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {
                                            Object.keys(data).map((d, i) =>
                                                d !== 'pivot' ?

                                                    <div className="col-sm-4 m-b-8" key={index}>
                                                        <div className="">{prettify(d)}</div>
                                                        <strong>{this.normalizeOutput(d, data[d])}</strong>
                                                    </div> :
                                                    <div>
                                                        <div>{"Value"}</div>
                                                        <div className="text-right"><span className="text-muted">
                                                                {this.normalizeOutput(d, data[d]['values'])}
                                                                </span></div>
                                                    </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

            </React.Fragment>
        )

    }
}

export default UserSubscription;