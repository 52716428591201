import React, {Component} from "react";
import helpers from '../constants';
import {API_Call} from "../util";
import {NotificationManager} from "react-notifications";
import InvoicesByCompany from './InvoicesByCompany';

export default class Invoices extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            invoices: [],
            error: null,
        })
    }

    componentDidMount() {
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/invoices/company/' + this.props.match.params.id)
            .then(res => {
                if (res.code === 800) {
                    this.setState(() => ({invoices: res.data}));
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="page-header">
                        <h1 className="page-title">
                        </h1>
                    </div>
                    {
                            <InvoicesByCompany invoices={this.state.invoices} companyID={this.props.match.params.id}/>
                    }

                </div>
            </div>
        )
    }


}