import React, {Component} from "react";
import PageHeader from "../Layout/PageHeader";
import SidebarComponent from "./CompanyDetailsTemplate/SidebarComponent";
import Content from "./CompanyDetailsTemplate/Content";
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";

class CompanyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            adminCompanyUser: null,
            currentTab: 'subscription'
        });
    }

    componentDidMount() {
        API_Call(helpers.authServiceUrl + '/api/saas/admin-user/' + this.props.match.params.id, 'GET').then(res => {
            if (res.code === 800) {
                this.setState({
                    adminCompanyUser: res.data,
                });
            }
            else {
                this.setState({error: res.message});
                NotificationManager.error(res.message, 'Error');
            }
        }).catch(err => {
            this.setState({error: err.message});
            NotificationManager.error(err.message, 'Error');
        });
    }

    handleTabBtnClick = (value) => this.setState({currentTab: value});

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader title={"Company Details"}/>

                    <div className="row">
                        <SidebarComponent
                            companyAdminUser={this.state.adminCompanyUser}
                            uuid={this.props.match.params.id}
                            onTabBtnClick={this.handleTabBtnClick}
                            currentTab={this.state.currentTab}
                        />
                        <div className="col-lg-8">
                            <Content
                                id={this.props.match.params.id}
                                currentTab={this.state.currentTab}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyDetails;