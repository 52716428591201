import React, {Component} from 'react';
import {API_Call} from "../util";
import helpers from "../constants";
import TotalCard from "../Layout/TotalCard";
import {NotificationManager} from "react-notifications";
import CustomTimeline from "../Layout/Timeline";


class ZohoEventsByCompany extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            events: [],
            eventsCount: 0,
            error: null
        });
    }

    componentDidMount() {
        var id = this.props.companyPlanId ? this.props.companyPlanId : this.props.match.params.companyId
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/web-hooks/company/' + id)
            .then(res => {
                if (res.code === 800)
                    this.setState({events: res.data, eventsCount: res.data.length});
                else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');
            });
    }

    render() {
        let cardDetail = {
            delta: '0',
            label: "Events",
            totalCount: this.state.eventsCount,
        };

        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="page-header">
                        <h1 className="page-title">
                            Zoho Events by Company
                        </h1>
                    </div>


                    <CustomTimeline
                        events={this.state.events}
                    />
                </div>
            </div>
        )
    }
}

export default ZohoEventsByCompany;