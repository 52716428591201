import React, {Component} from "react";

class PageHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-header">
                <h1 className="page-title">
                    {this.props.title}
                </h1>
            </div>
        )
    }
}

export default PageHeader;
