import React, {Component} from "react";
import NotificationForm from "./NotificationForm";
import Popup from "reactjs-popup";


export default class NotificationPopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Popup trigger={<button title={"Send Notification"} className={"btn btn-primary btn-sm"}><i className={"fe fe-send"}/> </button>}
                   position="left center"
                   modal
                   closeOnDocumentClick>
                {close => (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="close-icon-modal">
                                        <i className="fa fa-times" onClick={close}/>
                                    </div>
                                    <NotificationForm
                                        companyID={this.props.companyID}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </Popup>
        )
    }
}