import React, {Component} from 'react';
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";
import SearchBar from "../Layout/SearchBar";
import Pagination from "react-js-pagination";

class ZohoEvents extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            events: [],
            eventsCount: 0,
            limit: 0,
            url: helpers.subscriptionServiceUrl + '/api/saas/web-hooks',
            activePage: 1,
            search: '',
            error: null
        });
    }

    componentDidMount() {
        this.getEvents();
    }

    getEvents = (urlAttr = '') => {
        API_Call(this.state.url + urlAttr)
            .then(res => {
                if (res.code === 800) {
                    this.setState({events: res.data, eventsCount: res.total, limit: res.limit});
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');
            });
    };

    setSearch = (activePage, search) => {
        this.setState({search: search}, () => {
            this.getEvents("?page=" + activePage + '&s=' + search);
        });
    };

    clearSearch = () => {
        this.setState({search: ''}, () => {
            this.handlePageChange(this.state.activePage, 1);
        });
    };

    handlePageChange = (pageNumber, force = false) => {
        if ((pageNumber !== this.state.activePage) || force) {
            this.setState({activePage: pageNumber});
            this.getEvents("?page=" + pageNumber + "&s=" + this.state.search);
        }
    };

    render() {
        return (
            <div className="">
                <div className="container">

                    <div className="page-header">
                        <h1 className="page-title">
                            Zoho Events
                        </h1>
                    </div>
                    <div className="row row-cards row-deck">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-md-6">
                                        <h3 className="card-title">Events ({this.state.eventsCount})</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right">
                                            <SearchBar clearSearch={this.clearSearch} setSearch={this.setSearch} url={this.state.url} activePage={this.state.activePage}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table
                                        className="table table-hover table-outline table-vcenter text-nowrap card-table">
                                        <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Occured At</th>
                                            <th>EventID</th>
                                            <th>Event Type</th>
                                            <th>Plan</th>
                                            <th>Amount</th>
                                            <th>Last Billed</th>
                                            <th>Next Billing On</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.events.map((event, index) => <React.Fragment key={index}>
                                                    <tr>
                                                        {/*<td><span className="text-muted">{index + 1}</span></td>*/}
                                                        <td>{(index + 1) - this.state.limit + this.state.limit * this.state.activePage} </td>
                                                        <td>{event.event_time} </td>
                                                        <td>{event.event_id}</td>
                                                        <td>{event.event_type}</td>
                                                        <td>
                                                            <div>
                                                                <strong>
                                                                    {event.subscription.plan.name}
                                                                </strong>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>{event.subscription.currency_code}
                                                                <strong> {event.subscription.currency_symbol} {event.subscription.amount}</strong>
                                                            </div>
                                                        </td>
                                                        <td>{event.subscription.last_billing_at}</td>
                                                        <td>{event.subscription.next_billing_at}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm"
                                                                    data-toggle="collapse"
                                                                    data-target={`#collapseEvent_${event.id}`}>
                                                                <i className="fe fe-list"/></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="collapse tableCollapseStyle"
                                                        id={`collapseEvent_${event.id}`}>
                                                        <td colSpan={8}>
                                                            <div>Activated At: {event.subscription.activated_at}</div>
                                                            <div>Expires At: {event.subscription.expires_at}</div>
                                                            <div>Repeats
                                                                Every: {event.subscription.interval} {event.subscription.interval_unit}</div>
                                                            <div></div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan={8}>
                                                <div>
                                                    <Pagination
                                                        itemsCountPerPage={this.state.limit}
                                                        pageRangeDisplayed={5}
                                                        activePage={this.state.activePage}
                                                        totalItemsCount={this.state.eventsCount}
                                                        onChange={this.handlePageChange}
                                                        innerClass="pagination  justify-content-center"
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ZohoEvents;