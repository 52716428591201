import Service from "./Service";
import helpers from "../constants";

export default class AuthService extends Service{
    constructor () {
        super();
    }

    getAllUsers = () => {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.authServiceUrl}/api/users`, {
            method: 'GET'
        }).then(res => {
            console.log(res);

        });
    }

    login = (username, password) => {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.authServiceUrl}/api/saas/token`, {
            method: 'POST',
            body: JSON.stringify({
                grant_type : 'password',
                client_id : helpers.authClientId,
                client_secret : helpers.authClientSecretKey,
                scope : "*",
                username: username,
                password: password,
            })
        }).then(res => {
            console.log(res);
            if(res.code === 800) {
                this.setToken(res.data.access_token); // Setting the token in localStorage
                this.setUserToken(res.data.user_token); // Setting the token in localStorage
                //this.setUserDetails(res.data.user_token); // Setting the token in localStorage
            }
            return Promise.resolve(res);

        })
    };



    fetch = (url, options) => {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        })
        .then(this._checkStatus)
        .then(response => response.json())
    };

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}
