import React, {Component} from 'react';
import {Timeline, TimelineEvent} from 'react-event-timeline';
import {prettify} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";
import TotalCard from "../Subscription/ZohoEventsByCompany";

class CustomTimeline extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            events: [],
            eventsCount: 0,
            error: null
        });
    }

    componentDidMount() {

    }

    /**
     * Function to check the event type and set icon
     * Default Icon sent at the moment
     * @param type
     * @returns {string}
     */
    setIcon = (type) => {
        switch (type) {
            case 'subscription_created':
            case 'subscription_activation':
            case 'subscription_ahead':
            case 'subscription_renewed':
            case 'trial_expiring':
            case 'billing_date_changed':
            case 'subscription_upgraded':
            case 'subscription_downgraded':
            case 'subscription_unpaid':
            case 'subscription_cancelled':
            case 'subscription_reactivated':
            case 'subscription_cancellation_scheduled':
            case 'subscription_expiring':
            case 'subscription_deleted':
            case 'invoice_created':
            case 'creditnote_added':
            case 'payment_thankyou':
            case 'payment_refunded':
            case 'payment_declined':
            case 'card_expired':
            case 'card_expiring':
            case 'card_deleted':
            default:
                return 'fa fa-calendar-check-o';
        }
    };

    render() {
        const events = this.props.events;
        return (
            <div className="row">
                <div className="col-sm-12">
                    <Timeline>
                        {
                            events && events.map(event =>
                                <TimelineEvent
                                    createdAt={event.event_time}
                                    icon={<i className={this.setIcon(event.event_type)}/>}
                                >
                                    {prettify(event.event_type)}
                                </TimelineEvent>
                            )
                        }

                    </Timeline>
                </div>
            </div>
        )
    }
}

export default CustomTimeline;