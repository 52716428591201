export default {
    authServiceUrl: "https://api.skeduler.com.au/services/authentication",
    subscriptionServiceUrl: "https://api.skeduler.com.au/services/subscription",
    xeroServiceUrl: "https://api.skeduler.com.au/services/xero",
    dbServiceUrl: "https://api.skeduler.com.au/services/database",
    schedulerUrl: "https://app.skeduler.com.au",
    //schedulerUrl: "http://localhost:8088",




    authClientSecretKey: "K0jhWMY4uF7MnxyEhAfJ7zDiGo2KnWZDS7ZwKvD2",
    authClientId: 2,

    // authServiceUrl: "http://localhost:8080",
    // subscriptionServiceUrl: "http://localhost:8084",
    // xeroServiceUrl: "http://localhost:8086",
    // dbServiceUrl: "http://localhost:8082",
};



