import React, {Component} from "react";


class DatabaseDetail extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-status bg-purple"></div>
                    <div className="card-header">
                        <h3 className="card-title">Database Detail</h3>
                    </div>
                    <div className="card-body">
                        <h3>Database Name : {this.props.databaseDetail.db_name} </h3>
                    </div>
                </div>
            </React.Fragment>
        )

    }
}

export default DatabaseDetail;
