import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentState: 'home'
        }
    }

    changeRoute = (condition) => {
        switch (condition) {
            case -1:
                window.history.back();
                break;
            case 0:
                window.location.reload();
                break;
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="header collapse d-lg-flex p-0" id="headerMenuCollapse">
                    <div className="container">
                        <div className="row align-items-center">
                            {/*<div className="col-lg-3 ml-auto">*/}
                            {/*<form className="input-icon my-3 my-lg-0">*/}
                            {/*<input type="search" className="form-control header-search"*/}
                            {/*placeholder="Search&hellip;" tabIndex="1"/>*/}
                            {/*<div className="input-icon-addon">*/}
                            {/*<i className="fe fe-search"></i>*/}
                            {/*</div>*/}
                            {/*</form>*/}
                            {/*</div>*/}
                            <div className="col-lg order-lg-first">
                                <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                                    <li className="nav-item">
                                        <NavLink to="/dashboard" className="nav-link" activeClassName="active"><i
                                            className="fe fe-home"></i> Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/users" className="nav-link" activeClassName="active"><i
                                            className="fe fe-user"></i> Companies</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/subscriptions" className="nav-link" activeClassName="active"><i
                                            className="fe fe-check"></i> Subscriptions</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/events" className="nav-link" activeClassName="active"><i
                                            className="fe fe-calendar"></i>Events</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/enquiry" className="nav-link" activeClassName="active"><i className="fe fe-file"/>Enquiry</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/notification" className="nav-link" activeClassName="active"><i
                                            className="fe fe-send"></i>Notification</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header collapse d-lg-flex p-0" id="headerMenuCollapse">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg order-lg-first">
                                <ul className="nav nav-tabs border-0 flex-column flex-lg-row p-t-12 p-b-12">
                                    <li className="nav-item" onClick={() => this.changeRoute(-1)}>
                                        <i className="fe fe-arrow-left"></i> Back
                                    </li>
                                    <li className="nav-item" onClick={() => this.changeRoute(0)}>
                                        <i className="fe fe-refresh-cw"></i> Refresh
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Menu;
