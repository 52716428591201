import React, {Component} from "react";
import {Link} from "react-router-dom";
import {API_Call, API_Call_Form_Data} from "../util";
import helpers from "../constants";
import {NotificationManager} from 'react-notifications';
import NotificationPopup from "../Notification/NotificationPopup";
import * as ReactDOM from "react-dom";
import SearchBar from "../Layout/SearchBar";
import Pagination from "react-js-pagination";
import Service from "../Services/Service";

class DashboardUserTable extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            users: [],
            url: helpers.authServiceUrl + '/api/saas/users',
            activateDeactivateUrl: helpers.authServiceUrl + '/api/saas/activateDeactivateAccount',
            portalUrl: '',
            activePage: 1,
            search: '',
            limit: 0,
            usersCount: 0,
            error: false
        });

        this.Service = new Service();
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = (urlAttr = '') => {
        API_Call(this.state.url + urlAttr, 'GET').then(res => {
            if (res.code === 800) {
                this.setState({users: res.data, usersCount: res.total, limit: res.limit});
            } else {
                this.setState({error: res.message});
                NotificationManager.error(res.message, 'Error');
            }
        }).catch(err => {
            this.setState({error: err.message});
            NotificationManager.error(err.message, 'Error');
        });
    };

    setSearch = (activePage, search) => {
        this.setState({search: search}, () => {
            this.getUsers("?page=" + activePage + '&s=' + search);
        });
    };

    clearSearch = () => {
        this.setState({search: ''}, () => {
            this.handlePageChange(this.state.activePage, 1);
        });
    };

    handlePageChange = (pageNumber, force = false) => {
        if ((pageNumber !== this.state.activePage) || force) {
            this.setState({activePage: pageNumber});
            this.getUsers("?page=" + pageNumber + "&s=" + this.state.search);
        }
    };

    portalLogin = (email, e) => {
        this.setState({
            username: email,
            user_token: this.Service.getUserToken(),
            auth_token: this.Service.getToken(),
            portalUrl: helpers.schedulerUrl + "/auth/portal/login",
        }, () => {
            ReactDOM.findDOMNode(this.refs.loginForm).submit();
        });

    };

    deactivateAccount = (email, e) => {
        if (!window.confirm("Are you sure you want to proceed?")) {
            return;
        }
        API_Call(this.state.activateDeactivateUrl + '/' + email, 'POST',null)
            .then(res => {
                if (res.code === 800) {
                    NotificationManager.success(res.message, 'Success');
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
                window.location.reload();
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            });
    };

    tableRow = (item, i) => ( //implicit return

            <tr key={i}>
            <td className="text-center">
                {(i + 1) - this.state.limit + this.state.limit * this.state.activePage}
            </td>
            {/*<td>*/}
            {/*<div>*/}
            {/*{item.id}*/}
            {/*</div>*/}
            {/*</td>*/}
            <td>
                <div>{item.first_name} {item.last_name}</div>
                <div className="small text-muted">
                    Registered: {item.created_at}
                </div>
            </td>
            <td>
                {item.email}
            </td>
            <td>
                <div><span
                    className={item.verified ? 'tag tag-lime' : 'tag tag-red'}>{item.verified ? 'Verified' : 'Not Verified'}</span>
                </div>
                {/*{item.activation_code ?*/}
                {/*<div className="small text-muted">*/}
                {/*Activation Code: {item.activation_code}*/}
                {/*</div>*/}
                {/*:*/}
                {/*""*/}
                {/*}*/}

            </td>
            <td className="text-center">
                <div>
                    {(item.company[0] ? item.company[0].identity : <span className="tag tag-red">Deactivated Account</span>)}
                </div>
            </td>
            <td className="text-center">
                <div className="btn-toolbar">
                    {/*<NotificationPopup companyID={item.company[0].identity}/>*/}
                    {item.company[0] ?
                        <Link to={"/company-details/" + item.company[0].identity} title={"View Details"} className="btn btn-orange btn-sm"><i className="fe fe-tag"/>  </Link>
                        : ''}

                    {!item.company[0] ?
                        <button type="button" title={"Activate this Account"} className="btn btn-green btn-sm" onClick={(e) => this.deactivateAccount(item.email, e)}><i className="fe fe-unlock"/></button>

                        :

                        <button type="button" title={"Deactivate this Account"} className="btn btn-red btn-sm" onClick={(e) => this.deactivateAccount(item.email, e)}><i className="fe fe-lock"/></button>
                    }
                    <button type="button" title={"Portal Sign In"} className="btn btn-blue btn-sm" onClick={(e) => this.portalLogin(item.email, e)}><i className="fe fe-log-in"/></button>
                </div>
            </td>
        </tr>

    );

    render() {
        return (

            <div className="col-12">

                <form target="_blank" action={this.state.portalUrl} method="POST" hidden ref="loginForm">
                    <input type="text" name="identity" ref="identity" value={this.state.username}/>
                    <input type="text" name="user_token" ref="token" value={this.state.user_token}/>
                    <input type="text" name="auth_token" ref="token" value={this.state.auth_token}/>
                    <input type="hidden" name="portal" value="true"/>
                    <input type="submit" id="formSubmit" ref="submitForm"/>
                </form>

                <div className="card">
                    <div className="card-header">
                        <div className="col-md-6"><h3 className="card-title">Users ({this.state.usersCount})</h3></div>
                        <div className="col-md-6">
                            <div className="pull-right">
                                <SearchBar clearSearch={this.clearSearch} setSearch={this.setSearch} url={this.state.url} activePage={this.state.activePage}/>
                            </div>
                        </div>


                    </div>
                    <div className="">
                        <div className="table-responsive">
                            <table
                                className="table table-hover table-outline table-vcenter text-nowrap card-table">
                                <thead>
                                <tr>
                                    <th className="text-center w-1"><i className="icon-people"/>Sn</th>
                                    {/*<th>ID#</th>*/}
                                    <th>USER</th>
                                    <th>EMAIL</th>
                                    <th>ACTIVATION</th>
                                    <th className="text-center">COMPANY-CODE</th>
                                    <th className="text-center"><i className="icon-settings"/></th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    this.state.users.map((user, index) =>
                                        this.tableRow(user, index)
                                    )
                                }

                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={7}>
                                        <div>
                                            <Pagination
                                                itemsCountPerPage={this.state.limit}
                                                pageRangeDisplayed={5}
                                                activePage={this.state.activePage}
                                                totalItemsCount={this.state.usersCount}
                                                onChange={this.handlePageChange}
                                                innerClass="pagination  justify-content-center"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default DashboardUserTable;

