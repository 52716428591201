import React, {Component} from 'react';
import './App.css';
import Dashboard from "./Components/Dashboard/Dashboard";
import AuthService from "./Components/Services/AuthService";
import Header from "./Components/Layout/Header";
import Menu from "./Components/Layout/Menu";
import CompanyDetails from "./Components/Users/CompanyDetails";
import {Route, Switch} from "react-router-dom";
import Users from "./Components/Users/Users";
import Login from "./Components/Auth/Login";
import Subscription from "./Components/Subscription/Subscription";
import SubscribedUsers from "./Components/Subscription/SubscribedUsers";
import NotFoundPage from "./Components/NotFoundPage";
import ZohoEvents from "./Components/Subscription/ZohoEvents";
import ZohoEventsByCompany from "./Components/Subscription/ZohoEventsByCompany";
import Invoices from "./Components/Invoices/Invoices";
import EnquiryList from "./Components/Enquiry/EnquiryList";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import Notification from "./Components/Notification/Notification";


class App extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = ({
            loggedInUser: []
        })
    }

    isLoggedIn = () => {
        return this.Auth.loggedIn();
    };

    logoutUser = () => {
        this.Auth.logout();
        window.location.replace("/login");
    };

    render() {
        return (
            <React.Fragment>
                {
                    this.isLoggedIn() && (
                        <div className="content-head page-main">
                            <Header
                                logoutUser={this.logoutUser}
                                loggedInUserName=
                                    {this.Auth.getLoggedInUserDetails().first_name + " " + this.Auth.getLoggedInUserDetails().last_name}
                            />

                            <Menu/>
                        </div>
                    )
                }

                <div className="content-body">
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path='/dashboard' component={Dashboard}/>
                        <Route exact path='/notification' component={Notification}/>
                        <Route path='/company-details/:id' component={CompanyDetails}/>
                        <Route path='/subscription/:id' component={CompanyDetails}/>
                        <Route exact path='/' component={Dashboard}/>

                        <Route exact path="/users" component={Users}/>

                        <Route exact path="/subscriptions" component={Subscription}/>
                        <Route exact path="/subscriptions/:planCode/:planName" component={SubscribedUsers}/>

                        <Route exact path="/events" component={ZohoEvents}/>
                        <Route exact path="/events/company/:companyId" component={ZohoEventsByCompany}/>

                        <Route exact path="/invoices/company/:id" component={Invoices}/>

                        <Route exact path="/enquiry" component={EnquiryList}/>

                        <Route component={NotFoundPage}/>
                    </Switch>
                </div>
                {/*This component is for displaying the notification alerts*/}
                <NotificationContainer/>
            </React.Fragment>
        );
    }
}

export default App;