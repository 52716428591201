import React, {Component} from "react";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="header py-4">
                <div className="container">
                    <div className="d-flex">
                        <a className="header-brand" href="./index.html">
                            <img src={require('../../Assets/saas_sked.png')} className="header-brand-img"
                                 alt="tabler logo"/>
                        </a>
                        <div className="d-flex order-lg-2 ml-auto">
                            <div className="dropdown">
                                <a href="#" className="nav-link pr-0 leading-none" data-toggle="dropdown">
                                    <span className="avatar avatar-blue avatar-placeholder"></span>
                                    <span className="ml-2 d-none d-lg-block">
                                  <span className="text-default">{this.props.loggedInUserName}</span>
                                  <small className="text-muted d-block mt-1">Administrator</small>
                                </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <a className="dropdown-item" href="#">
                                        <i className="dropdown-icon fe fe-user"></i> Profile
                                    </a>

                                    <div className="dropdown-divider"></div>
                                  {/*  <a className="dropdown-item" href="#">
                                        <i className="dropdown-icon fe fe-help-circle"></i> Need help?
                                    </a>*/}
                                    <a className="dropdown-item" href="javascript:;" onClick={this.props.logoutUser}>
                                        <i className="dropdown-icon fe fe-log-out"></i> Sign out
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="#" className="header-toggler d-lg-none ml-3 ml-lg-0" data-toggle="collapse"
                           data-target="#headerMenuCollapse">
                            <span className="header-toggler-icon"></span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
