import React, {Component} from "react";
import {Link} from 'react-router-dom' ;
import {API_Call, showFeatureDOMS, nullChecker} from "../util";
import helpers from '../constants';
import {NotificationManager} from "react-notifications";

class SubscriptionPlans extends Component {

    constructor(props) {
        super(props);

        this.state = ({
            plans: [],
            plansCount: 0,
            error: null
        });
    }

    componentDidMount() {
        API_Call(helpers.subscriptionServiceUrl + '/api/saas/plans')
            .then(res => {
                if (res.code === 800) {
                    this.setState({plans: res.data, plansCount: res.data.length});
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="card m-b-0">
                        <div className="card-header">
                            <h3 className="card-title">Active Plans ( {this.state.plansCount} )</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.plans.map((plan, index) =>
                            <div className="card col-md-3 col-sm-6" key={index}>
                                <div className="card-body d-flex flex-column">
                                    <h4>${plan.details.recurring_price} ( {plan.details.interval_unit} )
                                        <span className="dropdown pull-right">
                                    <button type="button"
                                            className="btn btn-secondary btn-sm dropdown-toggle"
                                            data-toggle="dropdown">
                                    </button>


                                    <div className="dropdown-menu">
                                        <Link to={`/subscriptions/${plan.code}/${plan.name}`}
                                              className="dropdown-item">View</Link>
                                        <a className="dropdown-item"
                                           href="#">Dropdownlink</a>
                                    </div>
                                </span>
                                    </h4>
                                    <div>
                                        <strong>
                                            {plan.details.status === "active" ?
                                                <span className="status-icon bg-success"></span> :
                                                <span className="status-icon bg-danger"></span>}
                                            {plan.name}
                                        </strong>
                                        <div className="text-capitalize">Plan Code: {plan.code}</div>
                                        <div className="font-italic">{plan.details.trial_period} days free
                                            trial.
                                        </div>
                                        <div className="m-t-8 m-b-8">
                                            {showFeatureDOMS(plan.parameters.features.call_tracking, 'Call Tracking')}
                                            {showFeatureDOMS(plan.parameters.features.call_tracking_number, 'Call Tracking Number')}
                                            {showFeatureDOMS(plan.parameters.features.email_support, 'Support')}
                                            {showFeatureDOMS(plan.parameters.features.phone_support, 'Email Support')}
                                            {showFeatureDOMS(plan.parameters.features.xero_integration, 'Phone Support')}
                                            {showFeatureDOMS(plan.parameters.features.xero_integration, 'Xero Integration')}
                                        </div>
                                    </div>
                                    <div className="text-muted">
                                        {nullChecker(plan.parameters.plan_description)}
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>


            </div>

        )
    }
}

export default SubscriptionPlans;