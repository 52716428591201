import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './util.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import AuthService from "./Components/Services/AuthService";
import App from "./App";

let Auth = new AuthService();

let isLoggedIn = () => {
    if(!Auth.loggedIn()) {
        return false;
    }
    return true;
}

ReactDOM.render(
        <Router>
                {
                    !isLoggedIn() ?
                        (
                            <React.Fragment>
                                <Redirect to={"/login"}/>
                                <App/>
                            </React.Fragment>
                        )
                    :
                    (
                       <App/>
                    )
                }
        </Router>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
