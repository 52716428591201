import Service from "./Services/Service";
import React from 'react';

let service = new Service();

export function API_Call(url, method = 'GET', data = null) {
    if (data != null) {
        return service.fetch(url, {
            method: method,
            body: data
        });
    }
    return service.fetch(url, {
        method: method
    });
}


export function API_Call_Form_Data(url, options) {
    return fetch(url, options);
}


export function showFeatureDOMS(data, value) {
    if (data)
        return (<div><span className="text-green"><i className="fe fe-check"/></span>&nbsp;{value}</div>);
    else
        return (<div><span className="text-red"><i className="fe fe-x"/></span>&nbsp;{value}</div>);
}

export function nullChecker(str) {
    if (str.length < 1 || !str || str === '')
        return '-';
    else
        return str;
}

export function removeUnderScore(str) {
    return str.replace('_', ' ');
}

export function capitalizeInitials(str) {
    return str.replace(/(?:^|\s)\S/g, l => l.toUpperCase());
}

export function prettify(str) {
    return capitalizeInitials(removeUnderScore(str));
}