import React, {Component} from "react";

import PageHeader from "../Layout/PageHeader";
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";
import NotificationForm from "./NotificationForm";

export default class Notification extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">
                    <PageHeader
                        title={"Notifications"}
                    />

                    <div className="col-lg-12">
                        <NotificationForm/>
                    </div>
                </div>
            </div>
        )
    }
}