import React, {Component} from "react";

import SubscriptionPlans from "./SubscriptionPlans";
import Addons from './Addons';
import TotalCard from "../Layout/TotalCard";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planCardDetail: {
                delta: '',
                label: "",
                totalCount: 0,
            },
            addonCardDetail: {
                delta: '',
                label: "",
                totalCount: 0,
            },
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getCardDetails = (cardDetail) => {
        this.setState((state) => ({
                planCardDetail: cardDetail.plan ? cardDetail.plan : state.planCardDetail,
                addonCardDetail: cardDetail.addon ? cardDetail.addon : state.addonCardDetail
            })
        );

    };

    render() {
        return (
            <div className="my-3 my-md-5">
                <div className="container">

                    <div className="page-header">
                        <h1 className="page-title">
                            Subscription
                        </h1>
                    </div>


                    {/*<div className="row">*/}
                        {/*<TotalCard details={this.state.planCardDetail}/>*/}
                        {/*<TotalCard details={this.state.addonCardDetail}/>*/}
                    {/*</div>*/}

                    <SubscriptionPlans getCardDetails={this.getCardDetails}/>
                    <Addons getCardDetails={this.getCardDetails}/>
                </div>
            </div>


        )
    }
}

export default Subscription;