import React, {Component} from "react";
import {NavLink} from 'react-router-dom' ;
import Pagination from "react-js-pagination";
import {API_Call} from "../../../util";
import {NotificationManager} from "react-notifications";
import helpers from "../../../constants";
import SearchBar from "../../../Layout/SearchBar";


class UserTable extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            error: false,
            users: [],
            activePage: 1,
            usersCount: 0,
            search: '',
            limit: 0,
            companyUsersUrl: helpers.authServiceUrl + "/api/saas/user/" + this.props.id
        });
    }

    componentDidMount() {
        this.API_getUsers();
    }

    handlePageChange = (pageNumber, force = false) => {
        if ((pageNumber !== this.state.activePage) || force) {
            this.setState({activePage: pageNumber});
            this.API_getUsers("?page=" + pageNumber + "&s=" + this.state.search);
        }
    };

    setSearch = (activePage, search) => {
        this.setState({search: search}, () => {
            this.API_getUsers("?page=" + activePage + '&s=' + search);
        });
    };
    clearSearch = () => {
        this.setState({search: ''}, () => {
            this.handlePageChange(this.state.activePage, 1);
        });
    };

    API_getUsers = (urlAttr = '') => {
        API_Call(this.state.companyUsersUrl + urlAttr, 'GET')
            .then(res => {
                if (res.code === 800) {
                    this.setState({
                        users: res.user.only_users,
                        usersCount: res.total,
                        limit: res.limit
                    }, () => {
                    });
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }

            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error fetching users data');
            });
    };


    tableRow = (item, index) => ( //implicit return
        <tr key={index}>
            <td className="text-center">

            </td>
            <td>
                <div>
                    {item.id}
                </div>
            </td>
            <td>
                <div>{item.first_name} {item.last_name}</div>
                <div className="small text-muted">
                    Registered: {item.created_at}
                </div>
            </td>
            <td>
                {item.email}
            </td>
            <td>
                <div><span
                    className={item.verified ? 'tag tag-lime' : 'tag tag-red'}>{item.verified ? 'Verified' : 'Not Verified'}</span>
                </div>
            </td>
            <td className="text-center">
                <div className="item-action dropdown">
                    <NavLink to="#" className="icon"><i className="fe fe-more-vertical"/></NavLink>
                </div>
            </td>
        </tr>
    );

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-status bg-red"/>
                    <div className="card-header">
                        <div className="col-md-6">
                            <h3 className="card-title">Users ({this.state.usersCount})</h3>
                        </div>
                        <div className="col-md-6">
                            <div className="pull-right">
                                <SearchBar clearSearch={this.clearSearch} setSearch={this.setSearch} url={this.state.companyUsersUrl} activePage={this.state.activePage}/>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table
                            className="table table-hover table-outline table-vcenter text-nowrap card-table">
                            <thead>
                            <tr>
                                <th className="text-center w-1"><i className="icon-people"/></th>
                                <th>#ID</th>
                                <th>USER</th>
                                <th> EMAIL</th>
                                <th>ACTIVATION</th>
                                <th className="text-center"><i className="icon-settings"/></th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                this.state.users.map((user, index) =>
                                    this.tableRow(user, index)
                                )
                            }

                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={6}>
                                    <div>
                                        <Pagination
                                            itemsCountPerPage={this.state.limit}
                                            pageRangeDisplayed={5}
                                            activePage={this.state.activePage}
                                            totalItemsCount={this.state.usersCount}
                                            onChange={this.handlePageChange}
                                            innerClass="pagination  justify-content-center"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </React.Fragment>


        )
    }
}

export default UserTable;


