import React, {Component} from "react";

class SidebarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            currentTab: props.currentTab
        });
    }

    handleTabBtnClick = (event) => {
        this.props.onTabBtnClick(event.target.id);
        this.setState({
            currentTab: event.target.id
        });
    };

    render() {
        let listClass = "list-group-item list-group-item-action d-flex align-items-center";
        let listClassWithActive = "list-group-item list-group-item-action d-flex align-items-center active";

        return (
            <div className="col-lg-4">
                <div className="card card-profile">
                    <div className="card-body text-center">
                        <span className="avatar avatar-xxl avatar-blue avatar-placeholder"/>
                        <p className="mb-3"><i className="fe fe-user"/> {this.props.companyAdminUser ? this.props.companyAdminUser.first_name +" " +
                            this.props.companyAdminUser.last_name : ''}</p>
                        <p className="mb-3"><i className="fe fe-mail"/> {this.props.companyAdminUser ? this.props.companyAdminUser.email : ''}</p>
                        <p className="mb-3"><i className="fe fe-phone"/> {this.props.companyAdminUser ? this.props.companyAdminUser.phone : ''}</p>

                        <p className="mb-4">
                            UUID: {this.props.uuid}
                        </p>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body">
                                <div className="list-group list-group-transparent mb-0">
                                    <a href="javascript:;" id={"subscription"} onClick={this.handleTabBtnClick}
                                       className={(this.state.currentTab === "subscription" ? listClassWithActive : listClass)}>
                                        <span className="icon mr-3"><i className="fe fe-inbox"/></span>Subscription
                                    </a>

                                    <a href="javascript:;" id={"users"} onClick={this.handleTabBtnClick}
                                       className={(this.state.currentTab === "users" ? listClassWithActive : listClass)}>
                                        <span className="icon mr-3"><i className="fe fe-users"/></span>Users
                                    </a>

                                    <a href="javascript:;" id={"database"} onClick={this.handleTabBtnClick}
                                       className={(this.state.currentTab === "database" ? listClassWithActive : listClass)}>
                                        <span className="icon mr-3"><i className="fe fe-hard-drive"/></span>Database
                                    </a>

                                    <a href="javascript:;" id={"zoho-notifications"} onClick={this.handleTabBtnClick}
                                       className={(this.state.currentTab === "zoho-notifications" ? listClassWithActive : listClass)}>
                                        <span className="icon mr-3"><i className="fe fe-hard-drive"/></span>Zoho Notifications
                                    </a>

                                    <a href="javascript:;" className="list-group-item list-group-item-action d-flex align-items-center">
                                        <span className="icon mr-3"><i className="fe fe-message-square"/></span>SMS
                                    </a>

                                    <a href="javascript:;" className="list-group-item list-group-item-action d-flex align-items-center">
                                        <span className="icon mr-3"><i className="fe fe-file-text"/></span>Xero
                                    </a>

                                    <a href="javascript:;" className="list-group-item list-group-item-action d-flex align-items-center">
                                        <span className="icon mr-3"><i className="fe fe-tag"/></span>Avanser
                                    </a>

                                    <a href="#" className="list-group-item list-group-item-action d-flex align-items-center">
                                        <span className="icon mr-3"><i className="fe fe-trash-2"/></span>Trash
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SidebarComponent;