import React, {Component} from 'react';

export default class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            search: '',
            error: null
        });
    }

    handleSubmit = (event) => {
        this.props.setSearch(this.props.activePage, this.state.search);
        event.preventDefault();
    };

    render() {
        return <div>
            <form className="input-icon my-3 my-lg-0" method="POST" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <div className="row gutters-xs">
                        <div className="col">
                            <input type="text" name="search" className="form-control" ref="search" placeholder="Search.."
                                   autoComplete="off" onChange={(event) => {
                                this.setState({search: event.target.value})
                            }}/>
                            <span className="input-icon-addon"><i className="fe fe-search"/></span>
                        </div>
                        <span className="col-auto">
                            <button className="btn btn-secondary" type="button" onClick={() => {
                                this.refs.search.value = '';
                                this.props.clearSearch();
                            }}>
                            <i className="fe fe-x fs-38" />
                            </button></span>
                    </div>
                </div>
            </form>
        </div>
    }
}