import React, {Component} from "react";

class TotalCard extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            delta: 0,
            deltaClassName: 'text-green',
            totalCount: 0,
            label: '',
        });
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if ( (prevProps.details.totalCount !== this.props.details.totalCount)) {

            this.setState((state) => ({
                delta: this.props.details.delta,
                deltaClassName: this.props.details.deltaClassName || state.deltaClassName,
                label: this.props.details.label,
                totalCount: this.props.details.totalCount,
            }));
        }
    }

    render() {
        return (
            <div className="col-6 col-sm-4 col-lg-2">
                <div className="card">
                    <div className="card-body p-3 text-center">
                        <div className={"text-right " + this.state.deltaClassName}>
                            {this.state.delta}%
                            <i className="fe fe-chevron-up"/>
                        </div>
                        <div className="h1 m-0">{this.state.totalCount}</div>
                        <div className="text-muted mb-4">{this.state.label}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TotalCard;
