import React, {Component} from "react";
import {API_Call} from "../util";
import helpers from "../constants";
import {NotificationManager} from "react-notifications";


export default class NotificationForm extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            error: false,
            companyID: null
        });
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.companyCode !== this.props.companyID) {
            this.setState({
                companyID: this.props.companyID
            })
        }

    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            message: event.target.message.value
        };
        //console.log(this.props.companyID);
        var submitUrl = this.state.companyID != null ?
            `${helpers.subscriptionServiceUrl}/api/saas/notification/` + this.state.companyID : `${helpers.subscriptionServiceUrl}/api/saas/notification`;

        API_Call(submitUrl, 'POST', JSON.stringify(data))
            .then(res => {
                if (res.code === 800) {
                    NotificationManager.success(res.message, 'Success');
                    document.getElementById("form").reset();
                } else {
                    this.setState({error: res.message});
                    NotificationManager.error(res.message, 'Error');
                }
            })
            .catch(err => {
                this.setState({error: err.message});
                NotificationManager.error(err.message, 'Error');

            });
    }

    render() {
        return (
            <form id="form" onSubmit={this.handleSubmit}>
                <div className="card-body">
                    <h3 className="card-title">Send Announcement
                        {this.props.companyID ? " for " + this.props.companyID : ""}
                    </h3>
                    {this.state.error ? this.state.error : ''}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group mb-0">
                                <label className="form-label">Announcement</label>
                                <textarea name="message" rows="5" className="form-control"
                                          placeholder="Announcement Message"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right">
                    <button type="submit" className="btn btn-primary">Send Now</button>
                </div>
            </form>
        )
    }
}

